import React from "react";
import ShowAnimatedText from "../ShowAnimatedText/ShowAnimatedText";
import ShowAudio from "../ShowAudio/ShowAudio";
import NewRenderer from "../../MarkdownRender/NewRenderer";

const ShowAnswer = ({ answer, animate, type }) => {
  return (
    <div className="flex flex-col dark:text-white font-normal font-render_ai_text text-sm w-full">
      {type === "audio" ? (
        <ShowAudio audioBlob={answer} />
      ) : animate ? (
        <ShowAnimatedText text={answer} />
      ) : (
        <NewRenderer markdown={answer} />
      )}
    </div>
  );
};

export default ShowAnswer;
