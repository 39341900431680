import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { Helmet } from "react-helmet-async";
import { AppDataContext } from "../context/appData";
import DashboardHeader from "../components/DashboardHeader";
import DashboardSidebar from "../components/DashboardSidebar";
import UserDashboard from "../components/UserDashboard";
import Hero from "../components/Hero";
import ShowAllCourses from "../components/ShowAllCourses";
// import ShowAllQuizes from "../components/ShowAllQuizes";
// import AttemptedQuizResults from "../components/AttemptedQuizResults";
import { getBotIds, setBotIds } from "../utils/network-requests";
import ReactGA from "react-ga4";
import { UserDashboardTabsData } from "../utils/staticData";

const Dashboard = () => {
  // navigate state
  const navigate = useNavigate();
  // user info context
  const {
    state: { userInfo, searchesLeftForUser, userBotInfo },
    updateSearchesLeftForUser,
    logoutUser,
    updateUserBotInfo,
    updateUserBotIdInfo,
  } = useContext(UserContext);

  // app data context
  const {
    state: { appData, allCoursesData, allQuizesData },
    initializeAllCoursesData,
    initializeAllQuizesData,
  } = useContext(AppDataContext);

  const [loading, setLoading] = useState(true);

  // selected tab
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`, // optional
    });
    setSelectedTab(tabID);
  };

  useEffect(() => {
    const isPersist = JSON.parse(localStorage.getItem("persist"));
    if (!Object.keys(userInfo).length && !isPersist) {
      navigate("/login", {
        replace: true,
      });
    }
    setLoading(false);
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0 && !searchesLeftForUser) {
      axios
        .get(getURLs("searches-left"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          updateSearchesLeftForUser(res?.data?.freeSearchesLeft);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!allCoursesData && Object.keys(userInfo || {}).length > 0) {
      axios
        .get(getURLs("all-books"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          initializeAllCoursesData(res.data?.AllCoursesData || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!allQuizesData && Object.keys(userInfo || {}).length > 0) {
      axios
        .get(getURLs("get-all-quizes"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          initializeAllQuizesData(res.data?.AllQuizesData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo, allCoursesData, allQuizesData]);

  // check for bot's and set
  const checkBotIdsAndSet = () => {
    Promise.all([
      getBotIds("math"),
      getBotIds("science"),
      getBotIds("english"),
      getBotIds("social"),
      getBotIds("french"),
      getBotIds("spanish"),
      getBotIds("counselor"),
    ])
      .then((res) => {
        const botIdsInfo = {
          mathBotId: res[0],
          scienceBotId: res[1],
          englishBotId: res[2],
          socialBotId: res[3],
          frenchBotId: res[4],
          spanishBotId: res[5],
          counselorBotId: res[6],
        };
        setBotIds(botIdsInfo, userInfo?.authToken);
        updateUserBotIdInfo({ ...botIdsInfo });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // check for bot id exist for the user
  useEffect(() => {
    if (Object.keys(userInfo)?.length && !Object.keys(userBotInfo)?.length) {
      const botInfo = sessionStorage.getItem("botInfo");
      if (botInfo) {
        updateUserBotInfo(JSON.parse(botInfo));
      } else {
        checkBotIdsAndSet();
      }
    }
  }, [userInfo, userBotInfo]);

  return (
    !loading && (
      <>
        <Helmet>
          <title>Brainjee - Dashboard</title>
          <link rel="canonical" href="https://www.brainjee.com/dashboard" />
        </Helmet>

        <div className="flex flex-col bg-white dark:bg-darkBgColor1">
          <DashboardHeader
            pageData={appData?.homePageData}
            userInfo={userInfo}
            selectedTab={selectedTab}
            handleTabClick={handleTabClick}
            TabsData={UserDashboardTabsData}
          />
          <div className="flex h-full">
            <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
              <DashboardSidebar
                userInfo={userInfo}
                selectedTab={selectedTab}
                handleTabClick={handleTabClick}
                TabsData={UserDashboardTabsData}
              />
            </div>
            <div className="lg:basis-3/4 rounded-md w-full px-2 lg:pr-2">
              {selectedTab === 1 && (
                <UserDashboard
                  allCoursesData={allCoursesData}
                  userInfo={userInfo}
                  pageData={appData}
                  searchesLeft={searchesLeftForUser}
                  setSelectedTab={setSelectedTab}
                />
              )}
              {selectedTab === 2 && <ShowAllCourses />}
              {/* {selectedTab === 3 && (
                <ShowAllQuizes
                  userInfo={userInfo}
                  allQuizesData={allQuizesData}
                />
              )}
              {selectedTab === 4 && <AttemptedQuizResults />} */}
              {selectedTab === 5 && <Hero useInDashboard tutor={"math"} />}
              {selectedTab === 6 && <Hero useInDashboard tutor={"science"} />}
              {selectedTab === 7 && <Hero useInDashboard tutor={"english"} />}
              {selectedTab === 8 && <Hero useInDashboard tutor={"social"} />}
              {selectedTab === 9 && <Hero useInDashboard tutor={"french"} />}
              {selectedTab === 10 && <Hero useInDashboard tutor={"spanish"} />}
              {selectedTab === 11 && (
                <Hero useInDashboard tutor={"counselor"} />
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Dashboard;
